.overflow {
  overflow: hidden;
}

.kv-main {
  position: relative;
  z-index: 5;
  height: 628px;
  background-image: url(../img/kv/kv_main-sp.jpg);
  background-position: center center;
  background-size: cover;

  @media (min-width: $lg-min) {
    max-height: 628px;
    height: calc(100vh - 100px);
    background-image: url(../img/kv/kv_main@2x.jpg);
  }

  &__bottle {
    position: absolute;

    @media (max-width: $md-max) {
      left: 50%;
      bottom: -51px;
      max-width: 233px;
      width: 65%;
      transform: translateX(-34%);
    }

    @media (min-width: $lg-min) {
      top: 45px;
      left: 50%;
      max-width: 341px;
      transform: translateX(-48%);
    }

    img {
      pointer-events: none;
      user-select: none;
      display: block;
      // @media (min-width: $lg-min) {
      //   width: auto;
      //   min-height: 659px;
      //   height: calc(106vh - 100px);
      // }
    }
  }

  &__text {
    position: absolute;
    top: 5%;
    left: 8%;

    @media (min-width: $sm-min) and (max-width: $md-max) {
      max-width: 352px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    @media (min-width: $lg-min) {
      top: 33%;
      left: 50%;
      max-width: 578px;
      width: 41%;
      transform: translateX(18%);
    }

    .title {
      margin: 0 0 10px;
      line-height: 1.6;
      // font-feature-settings: "palt" 1;
      font-size: 2.7rem;
      color: #323232;
      // letter-spacing: 13px;
      letter-spacing: 10px;

      @media (min-width: $lg-min) {
        margin: 0 0 24px;
        line-height: 1.9;
        font-size: 4rem;
        // letter-spacing: 32px;
        letter-spacing: 16px;
      }
    }
    .pal {
      margin-left: -0.4em;
    }
    .text {
      line-height: 1.7;
      font-size: 1.2rem;
      color: #5D574B;

      @media (min-width: $lg-min) {
        font-size: 1.4rem;
      }
    }
  }

  .scroll-bar {
    position: absolute;
    left: 11px;
    bottom: 0;
    color: #fff;
    // mix-blend-mode: exclusion;
    transform: translateY(37px);

    @media (min-width: $lg-min) {
      left: 40px;
      color: $color-main;
      transform: translateY(37px);
    }

    svg {
      width: 100%;
      height: 100%;
    }

    &__scroll {
      width: 16px;
      height: 55px;
      margin-bottom: 8px;
      svg {
        backface-visibility: hidden;
      }
    }
    &__bar {
      position: relative;
      width: 1px;
      height: 141px;
      margin-left: 5px;

      .bar-anim {
        animation: strokeOffset 2.5s ease-in-out 0s infinite reverse forwards;
      }

      .bar-large {
        stroke-width: 1;
        stroke-dasharray: 141;
        stroke-dashoffset: 0;
      }

      .bar-min-wrap {
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        width: 1px;
        height: 37px;
        overflow: hidden;
      }

      .bar-min {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 141px;
        color: #707070;
        stroke-width: 1;
        stroke-dasharray: 141;
        stroke-dashoffset: 0;
      }
    }
  }
}

@keyframes strokeOffset {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 282;
  }
}


.sec01 {
  padding: 204px 0 67px;

  @media (min-width: $lg-min) and (max-width: $lg-max) {
    padding-top: 248px;
  }
  &__container {
    position: relative;
    @media (max-width: $lg-max) {
      max-width: 750px;
      margin: 0 auto;
    }
    @media (min-width: $xl-min) {
      display: flex;
      // max-width: 1366px;
      margin: 0 auto;
    }
  }

  &__image {
    @media (max-width: $lg-max) {
      margin: 0 0 40px;
    }
    @media (min-width: $xl-min) {
      width: 58.5%;

      img {
        display: block;
        max-width: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__text {
    flex: 1 1 0%;
    @media (min-width: $xl-min) {
      margin-left: 61px;
    }
    .title {
      margin: 0;
      @media (max-width: $lg-max) {
        position: absolute;
        top: -155px;
        left: 0;
        z-index: 1;
        width: 100%;
        text-align: center;
      }
      @media (min-width: $xl-min) {
        max-width: 575px;
        margin-left: -148px;
        margin-right: 26px;
        margin-top: -53px;
        margin-bottom: 34px;
      }
    }

    .text {
      line-height: 2;
      font-size: 1.1rem;
      @media (max-width: $lg-max) {
        padding: 0 26px;
        text-align: center;
        letter-spacing: 1.5px;
        font-feature-settings: "palt";
      }
      @media (min-width: $xl-min) {
        padding-bottom: 74px;
        padding-right: 26px;
        font-size: 1.3rem;
      }
    }
  }
}


.brand {
  padding: 50px 0 64px;
  color: $color-sub;
  background-color: #002128;
  background: linear-gradient(-180deg, #002128 0%, #01404d 100%);

  @media (min-width: $xl-min) {
    padding: 75px 0 70px;
  }

  &__container {
    max-width: 554px + 52px;
    margin: 0 auto;
    padding: 0 26px;

    @media (min-width: $xl-min) {
      max-width: 1074px + 52px;
    }
  }

}
.brand-item {
  margin-bottom: 57px;
  @media (min-width: $xl-min) {
    display: flex;
    align-items: center;
    margin: 0 -40px 121px;

    > div {
      padding: 0 40px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__image {
    @media (max-width: $lg-max) {
      margin-bottom: 21px;
      margin-left: -6px;
      margin-right: -6px;
    }
    @media (min-width: $xl-min) {
      flex: 1 1 0%;
      margin-left: -69px;
    }
  }

  &__text {
    @media (min-width: $xl-min) {
      width: 50%;
    }

    .title {
      margin: 0 0 15px;
      font-size: 2.4rem;
      letter-spacing: 2px;

      @media (min-width: $xl-min) {
        margin: 0 0 25px;
        font-size: 3.1rem;
        letter-spacing: 6px;
      }
    }
    .hr {
      height: 2px;
      margin-top: 15px;
      margin-bottom: 15px;
      opacity: 0.7;
      border: 0;
      // background-image: radial-gradient(#D8D1B0 0%, #D2CAA9 100%);
      background-repeat: no-repeat;
      background-image: url(../img/sec02/bar_sp.png);
      background-size: 100% 2px;
      // border-radius: 100%/3px;

      @media (min-width: $xl-min) {
        height: 3px;
        margin-left: -120px;
        margin-top: 25px;
        margin-bottom: 25px;
        background-image: url(../img/sec02/bar_large.png);
      }
    }
    .hr-first {
      max-width: 415px;
      height: 2px;
      @media (min-width: $xl-min) {
        background-image: url(../img/sec02/bar_min.png);
      }
    }

    .text {
      line-height: 2;
      font-size: 1.3rem;

      @media (min-width: $xl-min) {
        font-size: 1.5rem;
      }
    }
  }

}
.brand-item__right {
  &.brand-item {
    @media (min-width: $xl-min) {
      flex-direction: row-reverse;
    }
  }
  .brand-item {
    &__image {
      @media (min-width: $xl-min) {
        margin-left: 0;
        margin-right: -69px;
      }
    }
    &__text {
      hr {
        @media (min-width: $xl-min) {
          margin-left: 0;
          margin-right: -120px;
        }
      }
      .text {
        @media (min-width: $xl-min) {
          // text-align: right;
        }
      }
    }
  }
}



.recommend {
  padding: 44px 0 37px;
  background-color: #DCDCDC;

  @media (min-width: $xl-min) {
    padding: 61px 0 72px;
  }

  &__container {
    max-width: 750px;
    margin: 0 auto;
    padding: 0 19px;

    @media (min-width: $xl-min) {
      max-width: 1266px + 100px;
      padding: 0 50px;
    }
  }

  .heading {
    color: #01404D;

    @media (max-width: $md-max) {
      margin-bottom: 22px;
    }
  }

  &__apply {
    margin-top: 27px;
    text-align: center;

    @media (min-width: $xl-min) {
      margin-top: 63px;
    }

    a {
      transition: 235ms ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.recommend-item {
  position: relative;
  padding: 20px 16px 24px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(#000, .16);

  @media (min-width: $xl-min) {
    padding: 57px 57px 72px 65px;
  }

  &__head {
    @media (min-width: $xl-min) {
      display: flex;
      margin-bottom: 28px;
    }
  }

  &__image {
    @media (max-width: $lg-max) {
      width: 50%;
      margin-bottom: 10px;
      padding-right: 8px;

      img {
        max-width: none;
        width: 100%;
      }
    }
    @media (min-width: $xl-min) {
      max-width: 302px;
      width: 30%;
    }
  }

  &__comment {
    padding: 2px;
    background-image: linear-gradient(90deg, #DAD3B2 0%, #A39177 40%, #A39177 60%, #DAD3B2 100%);
    background-size: 28% 100%;

    @media (min-width: $xl-min) {
      flex: 1 1 0%;
      margin-left: 14px;
      padding: 4px;
    }

    .frame {
      height: 100%;
      padding: 15px 18px;
      background-color: #fff;

      @media (min-width: $xl-min) {
        padding: 40px 56px 44px 56px;
      }
    }
    .title {
      margin: 0 0 17px;
      font-size: 1.6rem;
      line-height: 1;

      @media (min-width: $xl-min) {
        margin-bottom: 30px;
        font-size: 3rem;
      }
    }
    .text {
      line-height: 2;
      font-size: 1.15rem;
      font-feature-settings: "palt";
      letter-spacing: 1px;

      @media (min-width: $xl-min) {
        font-size: 1.4rem;
        letter-spacing: 1.5px;
      }
    }
  }

  &__foot {
    margin-top: 12px;

    @media (min-width: $xl-min) {
      display: flex;
    }
  }

  &__name {
    @media (max-width: $lg-max) {
      position: absolute;
      top: 20px;
      left: 50%;
      right: 16px;
      margin-left: 8px;

      .inner {
        padding-top: 50%;
      }
      .inner-wrap {
        transform: translateY(-50%);
      }
    }

    @media (min-width: $xl-min) {
      max-width: 230px;
      width: 24%;
      padding-left: 12px;
    }

    .inner-wrap {
      > span {
        display: block;
      }
    }

    .managed {
      font-size: 1.5rem;
      color: #baab8f;
      letter-spacing: 2px;
    }
    .name {
      margin: 10px 0;
      font-size: 2.3rem;
      letter-spacing: 2px;
    }
    .romaji {
      opacity: 0.52;
      font-family: $font-garamond;
      font-size: 1.3rem;
      letter-spacing: 2px;
    }
  }

  &__profile {
    @media (min-width: $xl-min) {
      flex: 1 1 0%;
      margin-left: 14px;
    }
    .text {
      line-height: 1.7;
      font-size: 1.1rem;

      @media (min-width: $xl-min) {
        line-height: 2.5;
        font-size: 1.4rem;
      }
    }
  }
}




.product {
  padding: 38px 0 57px;

  @media (min-width: $lg-min) {
    padding: 68px 0 70px;
  }

  .heading {
    margin-bottom: 30px;
    color: #9E8A73;

    @media (min-width: $lg-min) {
      margin-bottom: 54px;
    }
  }

  &__container {
    max-width: 480px;
    margin: 0 auto;
    padding: 0 26px;

    @media (min-width: $lg-min) {
      max-width: 954px;
    }
  }
}

.product-item {
  @media (min-width: $lg-min) {
    display: flex;
    align-items: center;
    margin: 0 -40px;
    > div {
      width: 50%;
      padding: 0 40px;
    }
  }
  &__image {
    text-align: center;
    @media (max-width: $md-max) {
      max-width: 235px;
      margin: 0 auto 26px;
      transform: translateX(13%);
    }
    @media (min-width: $lg-min) {
      text-align: right;
    }
  }

  &__text {
    @media (min-width: $lg-min) {
      margin-left: auto;
      margin-right: -25px;
    }
    .head {

    }
    .lede {
      margin: 14px 0;
      line-height: 2;
      font-feature-settings: "palt";
      font-size: 1.3rem;
      color: #323232;
      letter-spacing: 1.3px;
      border-top: 1px solid #9E8A73;
      border-bottom: 1px solid #9E8A73;
      p {
        margin: 10px 0 8px;
      }
    }

    .data-table {
      width: 100%;
      font-size: 1.3rem;
      color: #5D574B;
      text-align: left;

      th, td {
        padding: 3px 0;
        line-height: 1.4;
      }
      th {
        width: 128px;
      }
    }
  }
}



.story {
  position: relative;

  &__container {
    padding: 0 26px;
  }

  .heading {
    margin-bottom: 45px;
    color: #D9CFB0;

    @media (min-width: $xl-min) {
      margin-bottom: 152px;
    }
  }
}
.story-item {
  margin: 0 -26px;
  padding: 50px 26px 46px;
  background: linear-gradient(180deg, #002128 0%, #01404d 100%);

  @media (min-width: $xl-min) {
    padding: 179px 0 93px;

    &:first-child {
      padding-top: 63px;
    }
  }

  &__row {
    @media (max-width: $lg-max) {
      max-width: 500px;
      margin: 0 auto;
    }
    @media (min-width: $xl-min) {
      display: flex;
      align-items: center;
      margin: 0 -40px;

      > div {
        padding: 0 40px;
      }
    }
  }

  &__image {
    position: relative;
    margin-left: -26px;
    margin-right: 19px;
    margin-bottom: 24px;
    @media (min-width: $xl-min) {
      width: 50%;
      margin: 0;
    }
    .h {
      margin: 0 0 20px;
      padding-left: 26px;
      @media (min-width: $xl-min) {
        position: absolute;
        top: -40px;
        left: 40px;
        right: 40px;
        text-align: right;
        transform: translateY(-100%);
      }
    }
  }

  &__text {
    @media (min-width: $xl-min) {
      flex: 1 1 0%;
    }
    .title {
      margin: 0 0 20px;
      line-height: 2;
      font-size: 1.8rem;
      color: #EEEFEE;
      letter-spacing: 1px;

      @media (min-width: $xl-min) {
        margin-bottom: 30px;
        font-size: 3.5rem;
        letter-spacing: 3px;
      }
    }
    .text {
      // max-width: 480px;
      line-height: 2;
      font-size: 1.3rem;
      color: #D9CFB0;

      @media (min-width: $xl-min) {
        line-height: 2.4;
        padding-left: 0;
        padding-right: 2em;
      }
    }
  }

  &__right {
    .story-item {
      &__row {
        @media (min-width: $xl-min) {
          flex-direction: row-reverse;
        }
      }
      &__image {
        @media (max-width: $lg-max) {
          margin-left: 19px;
          margin-right: -26px;

          .h {
            padding-left: 0;
          }
        }
        @media (min-width: $xl-min) {
          .h {
            left: 40px;
            right: 40px;
            text-align: left;
            padding-left: 69px;
            padding-right: 26px;
          }
        }
      }
      &__text {
        @media (max-width: $lg-max) {
          margin-left: 19px;
        }
        @media (min-width: $xl-min) {
          max-width: 780px;
          // text-align: right;
        }

        .title {
          @media (min-width: $xl-min) {
            margin-left: auto;
            padding-left: 40px;
            padding-right: 0;
            // text-align: right;
          }
        }

        .text {
          @media (min-width: $xl-min) {
            margin-left: auto;
            padding-left: 40px;
            padding-right: 0;
          }
        }
      }
    }
  }
}

.tasting {
  padding: 61px 0 56px;
  background-color: #000;

  @media (min-width: $xl-min) {
    padding: 68px 0 0;
  }

  .heading {
    margin: 0 0 27px;
    font-weight: 300;
    color: #AC9D7A;

    @media (min-width: $xl-min) {
      margin: 0 0 86px;
    }
    .ja {
      text-indent: 2px;
      letter-spacing: 2px;
    }
  }
}
.tasting-content {
  position: relative;
  &__grass {
    padding: 0 26px;
    text-align: center;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  &__text {
    margin: 27px 0 44px;
    padding: 0 17px;
    text-align: center;

    @media (min-width: $xl-min) {
      position: absolute;
      z-index: 1;
      left: 50%;
      margin: 0;
      text-align: left;

      &.text1 {
        top: 50px;
        transform: translateX(-580px);
      }
      &.text2 {
        top: 50%;
        left: auto;
        right: 49%;
        // text-align: right;
        transform: translateX(568px) translateY(-90%);
        .title {
          justify-content: flex-start;
        }
        .hr {
          margin-left: 0;
          margin-right: -30px;
        }

        .text {
          padding-left: 110px;
        }
      }
      &.text3 {
        top: 55%;
        transform: translateX(-574px);
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      margin: 0 0 6px;

      @media (min-width: $xl-min) {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 15px;
      }

      .ja {
        margin-bottom: 3px;
        font-size: 1.4rem;
        color: rgba(#fff, .64);

        @media (min-width: $xl-min) {
          margin-left: 28px;
        }
      }

      .en {
        font-family: $font-copper;
        font-weight: 300;
        font-size: 2.8rem;
        color: #BAAB8F;
        letter-spacing: 6px;
        -webkit-text-stroke: 0;
      }
    }
    .hr {
      margin: 6.5px auto 15px;
      width: 252px;
      height: 1px;
      background-color: transparent;
      background-image: url(../img/sec06/bar@2x.png);
      background-size: 100% 100%;
      border: 0;

      @media (min-width: $xl-min) {
        width: 466px;
        margin: 15px 0 20px;
        margin-left: -30px;
      }
    }
    .text {
      line-height: 2;
      font-size: 1.1rem;
      color: #C1BEB6;

      @media (min-width: $xl-min) {
        font-size: 1.4rem;
      }
    }
  }
}



.pairing {
  padding: 50px 0 40px;
  background-color: #DEDCDC;
  .heading {
    margin-bottom: 27px;
    color: #AC9D7A;

    @media (min-width: $lg-min) {
      margin-bottom: 65px;
    }
    .ja {
      letter-spacing: 4px;
    }
  }

  &__more {
    margin: 32px 0 0;
    padding: 0 26px;
    text-align: center;

    @media (min-width: $lg-min) {
      margin: 56px 0 0;
    }

    a {
      transition: 235ms ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  // .swiper {
  //   max-width: 717px;
  //   overflow: visible;
  // }
}
.pairing-slider {
  // overflow: hidden;
}
.pairing-swiper {
  padding-left: 17px;
  padding-right: 17px;
  overflow: hidden;

  .swiper-wrapper {
  }
  .swiper-slide {
    pointer-events: none;
    user-select: none;
    max-width: 717px;
    opacity: 0.65;
    transform: scale(0.8);
    transition: 1s ease;
  }
  .swiper-slide-active {
    opacity: 1;
    transform: scale(1);
  }
  &-button-next,
  &-button-prev {
    top: 30%;
    width: 25px;
    height: 44px;
    background-color: rgba(#DEDCDC, .79);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px 25px;
    border: 1px solid rgba(#fff, .79);
    transition: 235ms ease;

    @media (min-width: $lg-min) {
      top: 38%;
      width: 40px;
      height: 69px;
      background-size: 16px 39px;
    }

    &:hover {
      opacity: 0.8;
    }

    &:after {
      display: none;
    }
  }
  &-button-next {
    right: 5px;
    background-image: url(../img/sec07/angle-right.svg);

    @media (min-width: $lg-min) {
      right: 50%;
      transform: translateX(445px);
    }
  }
  &-button-prev {
    left: 5px;
    background-image: url(../img/sec07/angle-left.svg);
    @media (min-width: $lg-min) {
      left: 50%;
      transform: translateX(-445px);
    }
  }
  &__content {
    max-width: 717px;
    width: 100%;
    margin: 0 auto;
  }

  &__text {
    position: relative;
    z-index: 1;
    margin: -13px 9px 0;
    padding: 18px 20px 20px;
    text-align: center;
    background-color: rgba(#fff, .65);
    border: 1px solid #C2B48D;

    @media (min-width: $lg-min) {
      margin: -16px 13px 0;
    }

    .title {
      margin: 0 0 8px;
      line-height: 1.6;
      font-size: 1.3rem;
      color: #5D574B;
      letter-spacing: 2px;

      @media (min-width: $lg-min) {
        font-size: 2.3rem;
      }
    }

    .hr {
      width: 237px;
      margin: 11px auto 8px;
      height: 1px;
      background: transparent url(../img/sec07/bar@2x.png) no-repeat center center;
      background-size: 100% 100%;
      border: 0;

      @media (min-width: $lg-min) {
        width: 373px;
        height: 2px;
      }
    }

    .text {
      line-height: 1.9;
      font-feature-settings: "palt";
      font-size: 1.2rem;
      color: #002128;
      letter-spacing: 1.5px;
    }
  }
}



.gift {
  padding: 36px 0 73px;

  @media (min-width: $lg-min) {
    padding: 68px 0 98px;
  }

  .heading {
    color: #AC9D7A;

    @media (max-width: $lg-max) {
      margin-bottom: 40px;
    }
  }
  &__container {
    max-width: 580px + 52px;
    margin: 0 auto;
    padding: 0 26px;

    @media (min-width: $lg-min) {
      max-width: 1074px + 52px;
    }
  }
}
.gift-content {
  @media (min-width: $lg-min) {
    display: flex;
    align-items: center;
    margin: 0 -26px;
  }

  &__image {
    margin: 0 0 28px;
    @media (min-width: $lg-min) {
      flex: 1 1 0%;
      margin-left: -70px;
      padding: 0 15px;
    }
  }

  &__text {
    @media (min-width: $lg-min) {
      width: 50%;
      padding: 0 15px;
      padding-left: 60px;
    }

    .title {
      margin: 0 0 20px;
      font-feature-settings: "palt";
      font-size: 2.1rem;
      color: #002128;
      letter-spacing: 5px;

      @media (min-width: $lg-min) {
        margin-top: -10px;
        margin-bottom: 38px;
        line-height: 1.9;
        font-size: 3.5rem;
        text-indent: -.5em;
      }
    }
    .text {
      line-height: 1.8;
      font-size: 1.3rem;
      color: #5D574B;

      @media (min-width: $lg-min) {
        font-size: 1.4rem;
      }
    }
  }
}


.faq {
  padding: 38px 0 54px;
  color: #D9CFB0;
  background-color: #030000;

  @media (min-width: $lg-min) {
    padding: 60px 0 117px;
  }

  .heading {
    margin-bottom: 50px;

    @media (min-width: $lg-min) {
      margin-bottom: 107px;
    }
  }

  &__container {
    max-width: 750px + 52px;
    margin: 0 auto;
    padding: 0 26px;
  }
}
.faq-content {}

.faq-item {
  display: block;
  margin: 0;

  &.active {
    .faq-down {
      transform: translateY(-50%) rotateX(180deg);
    }
  }

  dt,dd {
    margin: 0;
    padding: 0;
  }
  dt {
    cursor: pointer;
    position: relative;
    padding: 10px 0;
    padding-left: 1.4em;
    padding-right: 40px;
    user-select: none;
    line-height: 2;
    font-size: 1.3rem;
    transition: 235ms ease;

    @media (min-width: $lg-min) {
    }

    &:hover {
      opacity: 0.8;
    }

    &:before {
      content: "Q.";
      position: absolute;
      left: 0;
      letter-spacing: 2px;
    }
  }
  dd {
    line-height: 1.8;
    font-size: 1.1rem;
    color: #fff;

    @media (min-width: $lg-min) {
      font-size: 1.2rem;
    }
  }

  .faq-toggle {
  }

  .faq-down {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 15px;
    height: 6px;
    transform: translateY(-50%);
    transition: 235ms ease;

    svg {
      display: block;
      width: 15px;
      height: 6px;
    }
  }

  .text {
    padding: 10px 0;
    border-top: 1px solid #707070;

    @media (min-width: $lg-min) {
      padding-right: 80px;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}



.apply {
  padding: 50px 0 44px;
  background-color: #DEDCDC;

  @media (min-width: $lg-min) {
    padding: 59px 0 93px;
  }

  .heading {
    @media (max-width: $lg-max) {
      margin-bottom: 30px;
    }
  }

  &__container {
    max-width: 1187px + 52px;
    margin: 0 auto;
    padding: 0 26px;
  }
}
.apply-step {
  position: relative;
  z-index: 1;
  max-width: 500px;
  margin: 0 auto 29px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #C2B48D;
  box-shadow: 0 3px 6px 0 rgba(#000, .16);

  @media (min-width: $lg-min) {
    max-width: none;
  }

  &__inner {
    padding: 44px 22px;

    @media (min-width: $lg-min) {
      padding: 63px 22px 85px;
    }
  }
}

.apply-form {
  form.klaviyo-form {
    max-width: 486px !important;
    float: none !important;
    margin: 0 auto !important;
    padding: 0 !important;
    background-color: transparent !important;

    .needsclick {
      min-height: 1px !important;
    }

    input[type="text"]:not(:focus),
    input[type="email"] {
      font-family: $font-family-serif !important;
      border-color: #969494 !important;

      &::placeholder {
        font-family: $font-family-serif !important;
      }
    }
    input[type="email"] {
      max-width: 367px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    button[type="button"] {
      width: 375px !important;
      margin: 0 auto 0 !important;
      padding: 18px 10px !important;
      font-family: $font-family-serif !important;
      font-weight: 400 !important;
      font-size: 2rem !important;
      color: #D9CFB0 !important;
      letter-spacing: 3px !important;
      border-radius: 0 !important;
      transition: 235ms ease !important;

      @media (min-width: $lg-min) {
        font-size: 2.3rem;
      }

      &:hover {
        opacity: 0.8 !important;
      }
    }

    // [data-testid="form-row"] {
    //   @media (max-width: $md-max) {
    //     flex-direction: column !important;
    //   }
    // }
  }

  .ql-container {
    font-family: $font-family-serif !important;
  }
  .ql-editor p,
  .ql-editor [class*="ql-font"] {
    font-family: $font-family-serif !important;
    // font-size: 1.2rem !important;
    // line-height: 2 !important;
    text-align: center !important;

    @media (min-width: $lg-min) {
      // font-size: 1.4rem !important;
    }
  }
}