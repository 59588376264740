/**
 * CSS Reset
 * Default StyleSheet Normalize
 *
 * @since 0.1
 * @author MIRAI Inc.
 */
* {
	&,
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

html {
	font-size: 62.5%;
	touch-action: manipulation;
}

html, body {
	width: 100%;
}

body {
	min-width: 300px;
	margin: 0;
	padding: 0;
  font-weight: bold;
	font-family: $font-family-serif;
	font-size: 1.6rem;
	color: $color-text;
	background-color: white;
  // -webkit-text-stroke: 0.2px;
	-webkit-text-size-adjust: 100%;
}

header,
footer,
nav,
figure,
figcaption,
main,
article,
section,
aside {
	display: block;
}

main {
	width: 100%;
}

a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
	touch-action: manipulation;
}

button {
	appearance: none;
	cursor: pointer;
	padding: 0.5em 1em;
	border: 1px solid #ccc;
}

input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime-local"],
input[type="number"],
input[type="color"] {
	appearance: none;
	font-size: 16px;
}

textarea {
	appearance: none;
	font-size: 16px;
}

button {
	cursor: pointer;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
}

a {
	color: $color-link;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}

ul, ol {
	padding-left: 40px;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
}
