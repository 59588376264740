.sr-only {
  display: none;
}

[sp],
.sp {
	@media (min-width: $lg-min) {
		display: none;
	}
}

[pc],
.pc {
  @media (max-width: $md-max) {
    display: none;
  }
}
[lg-max] {
  @media (min-width: $xl-min) {
    display: none;
  }
}
[xl-min] {
  @media (max-width: $lg-max) {
    display: none;
  }
}

a[href^="tel:"] {
	pointer-events: none;

	@media (pointer: fine) {
		pointer-events: none;
		color: currentColor;
		text-decoration: none;
	}

	@media (pointer: coarse) {
		pointer-events: auto;
	}
}

.heading {
  margin: 0 0 65px;
  line-height: 1;
  text-align: center;

  .h-img {
    margin: 0 0 16px;
    line-height: 1;

    @media (min-width: $lg-min) {
      margin: 0 0 25px;
    }
    img {
      display: block;
      width: auto;
      height: 17px;
      margin: 0 auto;

      @media (min-width: $lg-min) {
        height: 21px;
      }
    }
  }
  .ja {
    display: block;
    line-height: 1.2;
    font-size: 1.3rem;
    color: currentColor;
    letter-spacing: 4px;
    text-indent: 4px;

    @media (min-width: $lg-min) {
      font-size: 1.7rem;
    }
  }
}

.number {
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	letter-spacing: 1px;
}

.text-left {
	text-align: left !important;
}
.text-center {
	text-align: center !important;
}
.text-right {
	text-align: right !important;
}

.text {
  > * {
    margin-bottom: 2rem;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.iframe {
	position: relative;
	display: block;
	width: 100%;
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
	}
}

.en {
	font-family: $font-en;
}


.share-buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 -5px;
	padding: 0;
	list-style: none;

	li {
		height: 100%;
		padding: 5px;
	}

	.fb-like {
		display: block !important;
		> span {
			display: block;
			vertical-align: top !important;
		}
	}
	iframe {
		display: block;
	}
}


.mlad {
  i {
    font-style: normal;
  }
  .ad {
    &:before {
      content: "info";
    }
  }
  .at {
    &:before {
      content: "@";
    }
  }
  .dom {
    &:before {
      content: "minaki-sake";
    }
    &:after {
      content: ".com";
    }
  }
}



.social-list {
  margin: 0;
  padding: 0;
  list-style: none;
}