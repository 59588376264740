body:not(.page-front) {
  .header {
    &__container {
      justify-content: center;
    }
    &__logo {
      margin-right: 0;
    }
  }
}
.header {
  padding: 20px 40px 20px 28px;
  background-color: $color-main;

  img {
    display: block;
  }

  &__container {
    display: flex;
    align-content: center;

    @media (max-width: $md-max) {
      justify-content: center;
    }
  }

  &__logo {
    margin: 0;
    @media (max-width: $md-max) {
      position: relative;
      z-index: 7;
    }
    @media (min-width: $lg-min) {
      margin-right: auto;
    }

    a {
      display: block;
      transition: 235ms ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.nav-toggle {
  appearance: none;
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: 0;
  transform: translateY(-50%);

  @media (min-width: $lg-min) {
    display: none;
  }

  .bars {
    position: relative;
    display: block;
    width: 18px;
    height: 13px;
  }
  .bar {
    position: absolute;
    right: 0;
    display: block;
    width: 100%;
    height: 1px;
    color: #BAAB8F;
    background-color: currentColor;
    transition: 500ms ease;

    &:nth-child(1) {
      top: 0;
      transform-origin: 100% 0;
    }
    &:nth-child(2) {
      top: 50%;
      transform: translateY(-1px);
    }
    &:nth-child(3) {
      top: auto;
      bottom: 0;
      transform-origin: 100% 100%;
    }
  }


  &.drawer-active {
    .bars {
      width: 14px;
      height: 14px;
      .bar {
        width: 19px;
        &:nth-child(1) {
          transform: rotate(-45deg);
        }
        &:nth-child(2) {
          width: 0;
          height: 0;
          right: 50%;
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(45deg);
        }
      }
    }

  }
}


.header-nav {
  @media (max-width: $md-max) {
    // display: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    opacity: 0;
    backface-visibility: hidden;
    background-color: #002128;
    transform: scale(0.9);
    transition: 500ms ease;

    &.drawer-active {
      pointer-events: all;
      opacity: 1;
      transform: scale(1);
    }
  }
  &__list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    @media (max-width: $md-max) {
      flex-direction: column;
      align-items: flex-start;
      max-width: 420px;
      margin: 0 auto;
      padding: 108px 26px;
    }

    li {
      padding: 0 5px;

      @media (max-width: $md-max) {
        padding: 18px 12px;
      }
    }

    a {
      display: block;
      padding: 5px 10px;
      transition: 235ms ease;

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: $md-max) {
        img {
          width: auto;
          height: 15px;
        }
      }
    }
  }
}