body:not(.page-front) {
  .footer {
    padding: 80px $gatter-size 100px;
    text-align: center;

    &__row {
      display: block;
    }

    &__logo {
      margin: 0 0 27px;
    }
  }
}
.copyright {
  width: 100%;
  margin: 0;
  line-height: 1.2;
  font-size: 1.1rem;
  color: #C8C8C8;

  @media (max-width: $md-max) {
    order: 4;
    text-align: center;
  }

  @media (min-width: $lg-min) {
    margin-top: -12px;
    font-size: 1rem;
    letter-spacing: 0;
  }
}
body:not(.page-front) {
  .copyright {
    margin-top: 0;
  }
}

.footer {
  padding: 36px $gatter-size 57px;
  background-color: $color-main;

  @media (min-width: $lg-min) {
    padding: 69px 104px 99px;
  }

  &__container {

  }

  &__caution {
    margin: 0 0 51px;
    line-height: 2;
    font-size: 1.2rem;
    color: #fff;

    @media (min-width: $lg-min) {
      margin-bottom: 83px;
      font-size: 1.3rem;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: $md-max) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__logo {
    @media (max-width: $md-max) {
      order: 3;
      margin-bottom: 40px;
    }
    @media (min-width: $lg-min) {
      margin-right: 100px;
    }
    @media (min-width: $xl-min) {
      margin-right: 113px;
    }
  }

  &__links {
    @media (max-width: $md-max) {
      order: 1;
      width: 100%;
      margin-bottom: 30px;
    }
    @media (min-width: $lg-min) {
      margin-right: 100px;
    }
    @media (min-width: $xl-min) {
      margin-right: 179px;
    }
  }

  &__sns {
    @media (max-width: $md-max) {
      margin-bottom: 61px;
      order: 2;
      margin-right: auto;
    }
    .social-list {
      display: flex;
      margin: -8px -16px;
      font-size: 2.3rem;

      li {
        padding: 8px 16px;
      }

      a {
        display: inline-block;
        color: $color-sub;
        transition: 235ms ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &__sns-title {
    margin: 0 0 24px;
    line-height: 1.4;
    font-size: 1.3rem;
    color: $color-sub;
    letter-spacing: 3px;

    @media (max-width: $md-max) {
      display: none;
    }
  }
}
.link-list-vertical {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  list-style: none;

  li {
    margin: 0 0 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-size: 1rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}