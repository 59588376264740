@charset "UTF-8";
/**
 * Default Setting
 */
/**
 * SuperGradient
 * SASS MULTI-COLOR GRADIENT MIXIN (LINEAR & RADIAL)
 * Options
 * $type: linear*, radial
 * $from: top*, left, top left, top right, bottom right, bottom left, center (radial)
 * $colors: hex, rgba
 * *default
 *
 * Include: bower install super-gradient
 *
 * Demo: http://super-gradient.ib.gs/
 */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #fff; }

::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.2); }

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2); }

/**
 * CSS Reset
 * Default StyleSheet Normalize
 *
 * @since 0.1
 * @author MIRAI Inc.
 */
*, *:before, *:after {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  touch-action: manipulation; }

html, body {
  width: 100%; }

body {
  min-width: 300px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: "A1明朝", "游明朝", "ヒラギノ明朝 ProN W3", "ＭＳ Ｐ明朝", serif;
  font-size: 1.6rem;
  color: #323232;
  background-color: white;
  -webkit-text-size-adjust: 100%; }

header,
footer,
nav,
figure,
figcaption,
main,
article,
section,
aside {
  display: block; }

main {
  width: 100%; }

a,
area,
button,
[role="button"],
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

button {
  appearance: none;
  cursor: pointer;
  padding: 0.5em 1em;
  border: 1px solid #ccc; }

input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime-local"],
input[type="number"],
input[type="color"] {
  appearance: none;
  font-size: 16px; }

textarea {
  appearance: none;
  font-size: 16px; }

button {
  cursor: pointer; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

a {
  color: #D9CFB0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

ul, ol {
  padding-left: 40px; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top; }

.sr-only {
  display: none; }

@media (min-width: 922px) {
  [sp],
  .sp {
    display: none; } }

@media (max-width: 921px) {
  [pc],
  .pc {
    display: none; } }

@media (min-width: 1200px) {
  [lg-max] {
    display: none; } }

@media (max-width: 1199px) {
  [xl-min] {
    display: none; } }

a[href^="tel:"] {
  pointer-events: none; }
  @media (pointer: fine) {
    a[href^="tel:"] {
      pointer-events: none;
      color: currentColor;
      text-decoration: none; } }
  @media (pointer: coarse) {
    a[href^="tel:"] {
      pointer-events: auto; } }

.heading {
  margin: 0 0 65px;
  line-height: 1;
  text-align: center; }
  .heading .h-img {
    margin: 0 0 16px;
    line-height: 1; }
    @media (min-width: 922px) {
      .heading .h-img {
        margin: 0 0 25px; } }
    .heading .h-img img {
      display: block;
      width: auto;
      height: 17px;
      margin: 0 auto; }
      @media (min-width: 922px) {
        .heading .h-img img {
          height: 21px; } }
  .heading .ja {
    display: block;
    line-height: 1.2;
    font-size: 1.3rem;
    color: currentColor;
    letter-spacing: 4px;
    text-indent: 4px; }
    @media (min-width: 922px) {
      .heading .ja {
        font-size: 1.7rem; } }

.number {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  letter-spacing: 1px; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.text > * {
  margin-bottom: 2rem; }
  .text > *:first-child {
    margin-top: 0; }
  .text > *:last-child {
    margin-bottom: 0; }

.iframe {
  position: relative;
  display: block;
  width: 100%;
  height: 0; }
  .iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%; }

.en {
  font-family: "Lato", sans-serif; }

.share-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -5px;
  padding: 0;
  list-style: none; }
  .share-buttons li {
    height: 100%;
    padding: 5px; }
  .share-buttons .fb-like {
    display: block !important; }
    .share-buttons .fb-like > span {
      display: block;
      vertical-align: top !important; }
  .share-buttons iframe {
    display: block; }

.mlad i {
  font-style: normal; }

.mlad .ad:before {
  content: "info"; }

.mlad .at:before {
  content: "@"; }

.mlad .dom:before {
  content: "minaki-sake"; }

.mlad .dom:after {
  content: ".com"; }

.social-list {
  margin: 0;
  padding: 0;
  list-style: none; }

/**
 * Extend
 */
/**
 * Common Parts
 */
/**
 * Form
 * フォームの各Input要素など
 *
 * @prefix form-
 */
input,
select,
textarea {
  appearance: none;
  max-width: 100%;
  font-size: 16px;
  outline: 0;
  border-radius: 4px;
  box-shadow: none; }

input:not([type=radio]):not([type=checkbox]):not([type=range]):not([type=color]):not([type=file])::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #999;
  transition: 235ms ease; }

input:not([type=radio]):not([type=checkbox]):not([type=range]):not([type=color]):not([type=file]):not(:placeholder-shown) + .form-error,
textarea:not(:placeholder-shown) + .form-error {
  height: 0;
  opacity: 0;
  visibility: hidden; }

input:not([type=radio]):not([type=checkbox]):not([type=range]):not([type=color]):not([type=file]):focus::placeholder,
textarea:focus::placeholder {
  color: #ccc; }

input:not([type=radio]):not([type=checkbox]):not([type=range]):not([type=color]):not([type=file]) {
  display: block;
  height: 40px;
  padding: 0 8px;
  border: 1px solid #ccc; }

input:not([type=radio]):not([type=checkbox]):not([type=range]):not([type=color]):not([type=file]),
textarea,
select {
  border: 1px solid #ccc; }
  input:not([type=radio]):not([type=checkbox]):not([type=range]):not([type=color]):not([type=file]):focus,
  textarea:focus,
  select:focus {
    border-color: #002128;
    box-shadow: 0 0 0 1px #002128;
    transition: 300ms ease; }

textarea {
  display: block;
  min-height: calc(5em * 1.4 + 8px);
  padding: 8px;
  line-height: 1.4;
  border-color: #ccc; }

select {
  position: relative;
  display: inline-block;
  width: auto;
  height: 40px;
  padding: 0 32px 0 8px;
  color: currentColor;
  background-repeat: no-repeat;
  background-position: right .7em top 50%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%226%22%20viewBox%3D%220%200%2010%206%22%3E%3Cpath%20d%3D%22M5%2C0l5%2C6H0Z%22%20transform%3D%22translate(10%206)%20rotate(180)%22%20fill%3D%22%23ccc%22%2F%3E%3C%2Fsvg%3E");
  border-color: #ccc; }
  select::-ms-expand {
    display: none; }

input[type=range] {
  display: block;
  height: 1.4em;
  margin: 0;
  padding: 0 4px;
  background-color: #e2e2e2;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 0 6px #fff; }
  input[type=range]:focus {
    box-shadow: 0 0 0 1px #002128, inset 0 0 0 6px #fff;
    transition: 300ms ease; }

input[type=color] {
  height: 32px;
  padding: 0 3px;
  line-height: 1;
  border-color: #ccc; }
  input[type=color]:focus {
    box-shadow: 0 0 0 1px #002128;
    transition: 300ms ease; }

input[type=radio],
input[type=checkbox] {
  cursor: pointer;
  width: 1em;
  height: 1em;
  margin: 0;
  font-size: 20px;
  color: #002128;
  line-height: 1;
  background-color: #fff;
  border: 1px solid #ccc; }
  input[type=radio]:focus,
  input[type=checkbox]:focus {
    border-color: #002128;
    box-shadow: 0 0 0 1px #002128;
    transition: 300ms ease; }
  input[type=radio]:checked,
  input[type=checkbox]:checked {
    background-color: #002128;
    background-image: linear-gradient(top right, #C5CE00 0%, #008F84 100%);
    box-shadow: inset 0 0 0 .17em #fff;
    transition: 300ms ease; }

input[type=radio] {
  border-radius: 1em; }

.form-file label {
  display: inline-block;
  padding: 8px 18px;
  line-height: 1.2;
  font-size: 16px;
  color: #fff;
  text-align: center;
  background-color: #002128;
  border: 1px solid #000c0f;
  border-radius: 30px; }
  .form-file label:focus {
    box-shadow: 0 0 0 1px #002128, inset 0 0 0 1px #002128;
    transition: 300ms ease; }
  .form-file label:before {
    content: "ファイルを選択";
    font-size: 14px; }
  .form-file label input[type=file] {
    display: none; }

.form-radio,
.form-checkbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px; }
  .form-radio .horizontal-item,
  .form-checkbox .horizontal-item {
    margin-right: 1em;
    margin-bottom: 8px; }
  .form-radio label,
  .form-checkbox label {
    cursor: pointer; }
    .form-radio label input,
    .form-radio label span,
    .form-checkbox label input,
    .form-checkbox label span {
      user-select: none;
      vertical-align: middle; }
    .form-radio label input,
    .form-checkbox label input {
      margin-right: 4px; }

.form-submit {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 2em;
  text-align: center; }
  @media (min-width: 768px) {
    .form-submit {
      flex-direction: row;
      justify-content: center;
      align-items: center; } }
  .form-submit button,
  .form-submit input[type=submit] {
    min-width: 240px;
    padding: 18px 16px;
    font-size: 16px;
    color: #fff;
    background-color: #002128;
    border: 0; }
  .form-submit button[value=back] {
    max-width: 60px;
    min-width: 30px;
    margin-top: 16px;
    padding: 8px 14px;
    line-height: 1;
    font-size: 14px;
    color: #999;
    background-color: #e2e2e2;
    border: 1px solid lightgray; }
    @media (min-width: 768px) {
      .form-submit button[value=back] {
        margin-top: 0;
        margin-right: 32px; } }

.form-group-column .form-group {
  flex-direction: column; }

.form-group-column .form-label {
  width: auto; }

.form-group-column .form-field {
  width: auto;
  padding-left: 0; }

.form-group {
  display: flex;
  margin-bottom: 2.4em; }
  @media (max-width: 767px) {
    .form-group {
      flex-direction: column; } }

.form-label {
  user-select: none;
  margin-bottom: .7em;
  font-weight: bold;
  font-size: 16px; }
  @media (min-width: 768px) {
    .form-label {
      width: 30%; } }

.form-field {
  position: relative; }
  @media (min-width: 768px) {
    .form-field {
      width: 70%;
      padding-left: 2em; } }

.form-error {
  pointer-events: none;
  user-select: none;
  transition: 235ms ease; }
  .form-error .error {
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    color: #C11414; }

.mw_wp_form_confirm .required {
  display: none; }

svg.icon {
  width: 1em;
  height: 1em; }

/**
* Components
*/
/**
 * Layout
 */
/**
 * Layout
 * レイアウト全般に関するものはここに書く
 */
body {
  display: flex;
  flex-direction: column; }

.guide {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  pointer-events: none; }
  .guide > * {
    position: absolute;
    top: 0;
    height: 100%;
    color: #0ff; }
  .guide .guide-center {
    left: 50%;
    width: 1px;
    background-color: currentColor; }
    .guide .guide-center:before, .guide .guide-center:after {
      content: "";
      position: absolute;
      top: 0;
      display: block;
      width: 1px;
      height: 100%;
      background-color: currentColor; }
    .guide .guide-center:before {
      left: -40px; }
    .guide .guide-center:after {
      left: 40px; }
  .guide .guide-container {
    left: 0;
    right: 0;
    max-width: 1074px;
    width: calc(100% - 52px);
    margin: 0 auto;
    border-left: 1px solid currentColor;
    border-right: 1px solid currentColor; }

.layout-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2); }

body.scrolldown:not(.drawer-active) .layout-header {
  transform: translateY(-100%);
  transition: 235ms ease 350ms; }

.layout-header {
  position: sticky;
  top: 0;
  z-index: 9999;
  transition: 200ms ease; }

.layout-main {
  flex: 1 0 auto; }

.container {
  max-width: 472px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 26px;
  padding-left: calc(env(safe-area-inset-left) + 26px);
  padding-left: calc(constant(safe-area-inset-left) + 26px);
  padding-right: 26px;
  padding-right: calc(env(safe-area-inset-right) + 26px);
  padding-right: calc(constant(safe-area-inset-right) + 26px); }
  @media (min-width: 576px) {
    .container {
      max-width: 572px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 732px; } }
  @media (min-width: 922px) {
    .container {
      max-width: 932px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1176px; } }

.row {
  margin-left: -26px;
  margin-right: -26px; }

body:not(.page-front) .header__container {
  justify-content: center; }

body:not(.page-front) .header__logo {
  margin-right: 0; }

.header {
  padding: 20px 40px 20px 28px;
  background-color: #002128; }
  .header img {
    display: block; }
  .header__container {
    display: flex;
    align-content: center; }
    @media (max-width: 921px) {
      .header__container {
        justify-content: center; } }
  .header__logo {
    margin: 0; }
    @media (max-width: 921px) {
      .header__logo {
        position: relative;
        z-index: 7; } }
    @media (min-width: 922px) {
      .header__logo {
        margin-right: auto; } }
    .header__logo a {
      display: block;
      transition: 235ms ease; }
      .header__logo a:hover {
        opacity: 0.8; }

.nav-toggle {
  appearance: none;
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: 0;
  transform: translateY(-50%); }
  @media (min-width: 922px) {
    .nav-toggle {
      display: none; } }
  .nav-toggle .bars {
    position: relative;
    display: block;
    width: 18px;
    height: 13px; }
  .nav-toggle .bar {
    position: absolute;
    right: 0;
    display: block;
    width: 100%;
    height: 1px;
    color: #BAAB8F;
    background-color: currentColor;
    transition: 500ms ease; }
    .nav-toggle .bar:nth-child(1) {
      top: 0;
      transform-origin: 100% 0; }
    .nav-toggle .bar:nth-child(2) {
      top: 50%;
      transform: translateY(-1px); }
    .nav-toggle .bar:nth-child(3) {
      top: auto;
      bottom: 0;
      transform-origin: 100% 100%; }
  .nav-toggle.drawer-active .bars {
    width: 14px;
    height: 14px; }
    .nav-toggle.drawer-active .bars .bar {
      width: 19px; }
      .nav-toggle.drawer-active .bars .bar:nth-child(1) {
        transform: rotate(-45deg); }
      .nav-toggle.drawer-active .bars .bar:nth-child(2) {
        width: 0;
        height: 0;
        right: 50%;
        opacity: 0; }
      .nav-toggle.drawer-active .bars .bar:nth-child(3) {
        transform: rotate(45deg); }

@media (max-width: 921px) {
  .header-nav {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    opacity: 0;
    backface-visibility: hidden;
    background-color: #002128;
    transform: scale(0.9);
    transition: 500ms ease; }
    .header-nav.drawer-active {
      pointer-events: all;
      opacity: 1;
      transform: scale(1); } }

.header-nav__list {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  @media (max-width: 921px) {
    .header-nav__list {
      flex-direction: column;
      align-items: flex-start;
      max-width: 420px;
      margin: 0 auto;
      padding: 108px 26px; } }
  .header-nav__list li {
    padding: 0 5px; }
    @media (max-width: 921px) {
      .header-nav__list li {
        padding: 18px 12px; } }
  .header-nav__list a {
    display: block;
    padding: 5px 10px;
    transition: 235ms ease; }
    .header-nav__list a:hover {
      opacity: 0.8; }
    @media (max-width: 921px) {
      .header-nav__list a img {
        width: auto;
        height: 15px; } }

body:not(.page-front) .footer {
  padding: 80px 26px 100px;
  text-align: center; }
  body:not(.page-front) .footer__row {
    display: block; }
  body:not(.page-front) .footer__logo {
    margin: 0 0 27px; }

.copyright {
  width: 100%;
  margin: 0;
  line-height: 1.2;
  font-size: 1.1rem;
  color: #C8C8C8; }
  @media (max-width: 921px) {
    .copyright {
      order: 4;
      text-align: center; } }
  @media (min-width: 922px) {
    .copyright {
      margin-top: -12px;
      font-size: 1rem;
      letter-spacing: 0; } }

body:not(.page-front) .copyright {
  margin-top: 0; }

.footer {
  padding: 36px 26px 57px;
  background-color: #002128; }
  @media (min-width: 922px) {
    .footer {
      padding: 69px 104px 99px; } }
  .footer__caution {
    margin: 0 0 51px;
    line-height: 2;
    font-size: 1.2rem;
    color: #fff; }
    @media (min-width: 922px) {
      .footer__caution {
        margin-bottom: 83px;
        font-size: 1.3rem; } }
  .footer__row {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 921px) {
      .footer__row {
        flex-direction: column;
        align-items: center; } }
  @media (max-width: 921px) {
    .footer__logo {
      order: 3;
      margin-bottom: 40px; } }
  @media (min-width: 922px) {
    .footer__logo {
      margin-right: 100px; } }
  @media (min-width: 1200px) {
    .footer__logo {
      margin-right: 113px; } }
  @media (max-width: 921px) {
    .footer__links {
      order: 1;
      width: 100%;
      margin-bottom: 30px; } }
  @media (min-width: 922px) {
    .footer__links {
      margin-right: 100px; } }
  @media (min-width: 1200px) {
    .footer__links {
      margin-right: 179px; } }
  @media (max-width: 921px) {
    .footer__sns {
      margin-bottom: 61px;
      order: 2;
      margin-right: auto; } }
  .footer__sns .social-list {
    display: flex;
    margin: -8px -16px;
    font-size: 2.3rem; }
    .footer__sns .social-list li {
      padding: 8px 16px; }
    .footer__sns .social-list a {
      display: inline-block;
      color: #D9CFB0;
      transition: 235ms ease; }
      .footer__sns .social-list a:hover {
        opacity: 0.8; }
  .footer__sns-title {
    margin: 0 0 24px;
    line-height: 1.4;
    font-size: 1.3rem;
    color: #D9CFB0;
    letter-spacing: 3px; }
    @media (max-width: 921px) {
      .footer__sns-title {
        display: none; } }

.link-list-vertical {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  list-style: none; }
  .link-list-vertical li {
    margin: 0 0 15px; }
    .link-list-vertical li:last-child {
      margin-bottom: 0; }
  .link-list-vertical a {
    font-size: 1rem;
    text-decoration: none; }
    .link-list-vertical a:hover {
      text-decoration: underline; }

/**
 * Pages
 */
/**
 * Pages
 * 各ページのScssファイルをここでインポートしてください
 */
.overflow {
  overflow: hidden; }

.kv-main {
  position: relative;
  z-index: 5;
  height: 628px;
  background-image: url(../img/kv/kv_main-sp.jpg);
  background-position: center center;
  background-size: cover; }
  @media (min-width: 922px) {
    .kv-main {
      max-height: 628px;
      height: calc(100vh - 100px);
      background-image: url(../img/kv/kv_main@2x.jpg); } }
  .kv-main__bottle {
    position: absolute; }
    @media (max-width: 921px) {
      .kv-main__bottle {
        left: 50%;
        bottom: -51px;
        max-width: 233px;
        width: 65%;
        transform: translateX(-34%); } }
    @media (min-width: 922px) {
      .kv-main__bottle {
        top: 45px;
        left: 50%;
        max-width: 341px;
        transform: translateX(-48%); } }
    .kv-main__bottle img {
      pointer-events: none;
      user-select: none;
      display: block; }
  .kv-main__text {
    position: absolute;
    top: 5%;
    left: 8%; }
    @media (min-width: 576px) and (max-width: 921px) {
      .kv-main__text {
        max-width: 352px;
        left: 0;
        right: 0;
        margin: 0 auto; } }
    @media (min-width: 922px) {
      .kv-main__text {
        top: 33%;
        left: 50%;
        max-width: 578px;
        width: 41%;
        transform: translateX(18%); } }
    .kv-main__text .title {
      margin: 0 0 10px;
      line-height: 1.6;
      font-size: 2.7rem;
      color: #323232;
      letter-spacing: 10px; }
      @media (min-width: 922px) {
        .kv-main__text .title {
          margin: 0 0 24px;
          line-height: 1.9;
          font-size: 4rem;
          letter-spacing: 16px; } }
    .kv-main__text .pal {
      margin-left: -0.4em; }
    .kv-main__text .text {
      line-height: 1.7;
      font-size: 1.2rem;
      color: #5D574B; }
      @media (min-width: 922px) {
        .kv-main__text .text {
          font-size: 1.4rem; } }
  .kv-main .scroll-bar {
    position: absolute;
    left: 11px;
    bottom: 0;
    color: #fff;
    transform: translateY(37px); }
    @media (min-width: 922px) {
      .kv-main .scroll-bar {
        left: 40px;
        color: #002128;
        transform: translateY(37px); } }
    .kv-main .scroll-bar svg {
      width: 100%;
      height: 100%; }
    .kv-main .scroll-bar__scroll {
      width: 16px;
      height: 55px;
      margin-bottom: 8px; }
      .kv-main .scroll-bar__scroll svg {
        backface-visibility: hidden; }
    .kv-main .scroll-bar__bar {
      position: relative;
      width: 1px;
      height: 141px;
      margin-left: 5px; }
      .kv-main .scroll-bar__bar .bar-anim {
        animation: strokeOffset 2.5s ease-in-out 0s infinite reverse forwards; }
      .kv-main .scroll-bar__bar .bar-large {
        stroke-width: 1;
        stroke-dasharray: 141;
        stroke-dashoffset: 0; }
      .kv-main .scroll-bar__bar .bar-min-wrap {
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        width: 1px;
        height: 37px;
        overflow: hidden; }
      .kv-main .scroll-bar__bar .bar-min {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 141px;
        color: #707070;
        stroke-width: 1;
        stroke-dasharray: 141;
        stroke-dashoffset: 0; }

@keyframes strokeOffset {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 282; } }

.sec01 {
  padding: 204px 0 67px; }
  @media (min-width: 922px) and (max-width: 1199px) {
    .sec01 {
      padding-top: 248px; } }
  .sec01__container {
    position: relative; }
    @media (max-width: 1199px) {
      .sec01__container {
        max-width: 750px;
        margin: 0 auto; } }
    @media (min-width: 1200px) {
      .sec01__container {
        display: flex;
        margin: 0 auto; } }
  @media (max-width: 1199px) {
    .sec01__image {
      margin: 0 0 40px; } }
  @media (min-width: 1200px) {
    .sec01__image {
      width: 58.5%; }
      .sec01__image img {
        display: block;
        max-width: none;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .sec01__text {
    flex: 1 1 0%; }
    @media (min-width: 1200px) {
      .sec01__text {
        margin-left: 61px; } }
    .sec01__text .title {
      margin: 0; }
      @media (max-width: 1199px) {
        .sec01__text .title {
          position: absolute;
          top: -155px;
          left: 0;
          z-index: 1;
          width: 100%;
          text-align: center; } }
      @media (min-width: 1200px) {
        .sec01__text .title {
          max-width: 575px;
          margin-left: -148px;
          margin-right: 26px;
          margin-top: -53px;
          margin-bottom: 34px; } }
    .sec01__text .text {
      line-height: 2;
      font-size: 1.1rem; }
      @media (max-width: 1199px) {
        .sec01__text .text {
          padding: 0 26px;
          text-align: center;
          letter-spacing: 1.5px;
          font-feature-settings: "palt"; } }
      @media (min-width: 1200px) {
        .sec01__text .text {
          padding-bottom: 74px;
          padding-right: 26px;
          font-size: 1.3rem; } }

.brand {
  padding: 50px 0 64px;
  color: #D9CFB0;
  background-color: #002128;
  background: linear-gradient(-180deg, #002128 0%, #01404d 100%); }
  @media (min-width: 1200px) {
    .brand {
      padding: 75px 0 70px; } }
  .brand__container {
    max-width: 606px;
    margin: 0 auto;
    padding: 0 26px; }
    @media (min-width: 1200px) {
      .brand__container {
        max-width: 1126px; } }

.brand-item {
  margin-bottom: 57px; }
  @media (min-width: 1200px) {
    .brand-item {
      display: flex;
      align-items: center;
      margin: 0 -40px 121px; }
      .brand-item > div {
        padding: 0 40px; } }
  .brand-item:last-child {
    margin-bottom: 0; }
  @media (max-width: 1199px) {
    .brand-item__image {
      margin-bottom: 21px;
      margin-left: -6px;
      margin-right: -6px; } }
  @media (min-width: 1200px) {
    .brand-item__image {
      flex: 1 1 0%;
      margin-left: -69px; } }
  @media (min-width: 1200px) {
    .brand-item__text {
      width: 50%; } }
  .brand-item__text .title {
    margin: 0 0 15px;
    font-size: 2.4rem;
    letter-spacing: 2px; }
    @media (min-width: 1200px) {
      .brand-item__text .title {
        margin: 0 0 25px;
        font-size: 3.1rem;
        letter-spacing: 6px; } }
  .brand-item__text .hr {
    height: 2px;
    margin-top: 15px;
    margin-bottom: 15px;
    opacity: 0.7;
    border: 0;
    background-repeat: no-repeat;
    background-image: url(../img/sec02/bar_sp.png);
    background-size: 100% 2px; }
    @media (min-width: 1200px) {
      .brand-item__text .hr {
        height: 3px;
        margin-left: -120px;
        margin-top: 25px;
        margin-bottom: 25px;
        background-image: url(../img/sec02/bar_large.png); } }
  .brand-item__text .hr-first {
    max-width: 415px;
    height: 2px; }
    @media (min-width: 1200px) {
      .brand-item__text .hr-first {
        background-image: url(../img/sec02/bar_min.png); } }
  .brand-item__text .text {
    line-height: 2;
    font-size: 1.3rem; }
    @media (min-width: 1200px) {
      .brand-item__text .text {
        font-size: 1.5rem; } }

@media (min-width: 1200px) {
  .brand-item__right.brand-item {
    flex-direction: row-reverse; } }

@media (min-width: 1200px) {
  .brand-item__right .brand-item__image {
    margin-left: 0;
    margin-right: -69px; } }

@media (min-width: 1200px) {
  .brand-item__right .brand-item__text hr {
    margin-left: 0;
    margin-right: -120px; } }

.recommend {
  padding: 44px 0 37px;
  background-color: #DCDCDC; }
  @media (min-width: 1200px) {
    .recommend {
      padding: 61px 0 72px; } }
  .recommend__container {
    max-width: 750px;
    margin: 0 auto;
    padding: 0 19px; }
    @media (min-width: 1200px) {
      .recommend__container {
        max-width: 1366px;
        padding: 0 50px; } }
  .recommend .heading {
    color: #01404D; }
    @media (max-width: 921px) {
      .recommend .heading {
        margin-bottom: 22px; } }
  .recommend__apply {
    margin-top: 27px;
    text-align: center; }
    @media (min-width: 1200px) {
      .recommend__apply {
        margin-top: 63px; } }
    .recommend__apply a {
      transition: 235ms ease; }
      .recommend__apply a:hover {
        opacity: 0.8; }

.recommend-item {
  position: relative;
  padding: 20px 16px 24px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  @media (min-width: 1200px) {
    .recommend-item {
      padding: 57px 57px 72px 65px; } }
  @media (min-width: 1200px) {
    .recommend-item__head {
      display: flex;
      margin-bottom: 28px; } }
  @media (max-width: 1199px) {
    .recommend-item__image {
      width: 50%;
      margin-bottom: 10px;
      padding-right: 8px; }
      .recommend-item__image img {
        max-width: none;
        width: 100%; } }
  @media (min-width: 1200px) {
    .recommend-item__image {
      max-width: 302px;
      width: 30%; } }
  .recommend-item__comment {
    padding: 2px;
    background-image: linear-gradient(90deg, #DAD3B2 0%, #A39177 40%, #A39177 60%, #DAD3B2 100%);
    background-size: 28% 100%; }
    @media (min-width: 1200px) {
      .recommend-item__comment {
        flex: 1 1 0%;
        margin-left: 14px;
        padding: 4px; } }
    .recommend-item__comment .frame {
      height: 100%;
      padding: 15px 18px;
      background-color: #fff; }
      @media (min-width: 1200px) {
        .recommend-item__comment .frame {
          padding: 40px 56px 44px 56px; } }
    .recommend-item__comment .title {
      margin: 0 0 17px;
      font-size: 1.6rem;
      line-height: 1; }
      @media (min-width: 1200px) {
        .recommend-item__comment .title {
          margin-bottom: 30px;
          font-size: 3rem; } }
    .recommend-item__comment .text {
      line-height: 2;
      font-size: 1.15rem;
      font-feature-settings: "palt";
      letter-spacing: 1px; }
      @media (min-width: 1200px) {
        .recommend-item__comment .text {
          font-size: 1.4rem;
          letter-spacing: 1.5px; } }
  .recommend-item__foot {
    margin-top: 12px; }
    @media (min-width: 1200px) {
      .recommend-item__foot {
        display: flex; } }
  @media (max-width: 1199px) {
    .recommend-item__name {
      position: absolute;
      top: 20px;
      left: 50%;
      right: 16px;
      margin-left: 8px; }
      .recommend-item__name .inner {
        padding-top: 50%; }
      .recommend-item__name .inner-wrap {
        transform: translateY(-50%); } }
  @media (min-width: 1200px) {
    .recommend-item__name {
      max-width: 230px;
      width: 24%;
      padding-left: 12px; } }
  .recommend-item__name .inner-wrap > span {
    display: block; }
  .recommend-item__name .managed {
    font-size: 1.5rem;
    color: #baab8f;
    letter-spacing: 2px; }
  .recommend-item__name .name {
    margin: 10px 0;
    font-size: 2.3rem;
    letter-spacing: 2px; }
  .recommend-item__name .romaji {
    opacity: 0.52;
    font-family: garamond-premier-pro-caption, serif;
    font-size: 1.3rem;
    letter-spacing: 2px; }
  @media (min-width: 1200px) {
    .recommend-item__profile {
      flex: 1 1 0%;
      margin-left: 14px; } }
  .recommend-item__profile .text {
    line-height: 1.7;
    font-size: 1.1rem; }
    @media (min-width: 1200px) {
      .recommend-item__profile .text {
        line-height: 2.5;
        font-size: 1.4rem; } }

.product {
  padding: 38px 0 57px; }
  @media (min-width: 922px) {
    .product {
      padding: 68px 0 70px; } }
  .product .heading {
    margin-bottom: 30px;
    color: #9E8A73; }
    @media (min-width: 922px) {
      .product .heading {
        margin-bottom: 54px; } }
  .product__container {
    max-width: 480px;
    margin: 0 auto;
    padding: 0 26px; }
    @media (min-width: 922px) {
      .product__container {
        max-width: 954px; } }

@media (min-width: 922px) {
  .product-item {
    display: flex;
    align-items: center;
    margin: 0 -40px; }
    .product-item > div {
      width: 50%;
      padding: 0 40px; } }

.product-item__image {
  text-align: center; }
  @media (max-width: 921px) {
    .product-item__image {
      max-width: 235px;
      margin: 0 auto 26px;
      transform: translateX(13%); } }
  @media (min-width: 922px) {
    .product-item__image {
      text-align: right; } }

@media (min-width: 922px) {
  .product-item__text {
    margin-left: auto;
    margin-right: -25px; } }

.product-item__text .lede {
  margin: 14px 0;
  line-height: 2;
  font-feature-settings: "palt";
  font-size: 1.3rem;
  color: #323232;
  letter-spacing: 1.3px;
  border-top: 1px solid #9E8A73;
  border-bottom: 1px solid #9E8A73; }
  .product-item__text .lede p {
    margin: 10px 0 8px; }

.product-item__text .data-table {
  width: 100%;
  font-size: 1.3rem;
  color: #5D574B;
  text-align: left; }
  .product-item__text .data-table th, .product-item__text .data-table td {
    padding: 3px 0;
    line-height: 1.4; }
  .product-item__text .data-table th {
    width: 128px; }

.story {
  position: relative; }
  .story__container {
    padding: 0 26px; }
  .story .heading {
    margin-bottom: 45px;
    color: #D9CFB0; }
    @media (min-width: 1200px) {
      .story .heading {
        margin-bottom: 152px; } }

.story-item {
  margin: 0 -26px;
  padding: 50px 26px 46px;
  background: linear-gradient(180deg, #002128 0%, #01404d 100%); }
  @media (min-width: 1200px) {
    .story-item {
      padding: 179px 0 93px; }
      .story-item:first-child {
        padding-top: 63px; } }
  @media (max-width: 1199px) {
    .story-item__row {
      max-width: 500px;
      margin: 0 auto; } }
  @media (min-width: 1200px) {
    .story-item__row {
      display: flex;
      align-items: center;
      margin: 0 -40px; }
      .story-item__row > div {
        padding: 0 40px; } }
  .story-item__image {
    position: relative;
    margin-left: -26px;
    margin-right: 19px;
    margin-bottom: 24px; }
    @media (min-width: 1200px) {
      .story-item__image {
        width: 50%;
        margin: 0; } }
    .story-item__image .h {
      margin: 0 0 20px;
      padding-left: 26px; }
      @media (min-width: 1200px) {
        .story-item__image .h {
          position: absolute;
          top: -40px;
          left: 40px;
          right: 40px;
          text-align: right;
          transform: translateY(-100%); } }
  @media (min-width: 1200px) {
    .story-item__text {
      flex: 1 1 0%; } }
  .story-item__text .title {
    margin: 0 0 20px;
    line-height: 2;
    font-size: 1.8rem;
    color: #EEEFEE;
    letter-spacing: 1px; }
    @media (min-width: 1200px) {
      .story-item__text .title {
        margin-bottom: 30px;
        font-size: 3.5rem;
        letter-spacing: 3px; } }
  .story-item__text .text {
    line-height: 2;
    font-size: 1.3rem;
    color: #D9CFB0; }
    @media (min-width: 1200px) {
      .story-item__text .text {
        line-height: 2.4;
        padding-left: 0;
        padding-right: 2em; } }
  @media (min-width: 1200px) {
    .story-item__right .story-item__row {
      flex-direction: row-reverse; } }
  @media (max-width: 1199px) {
    .story-item__right .story-item__image {
      margin-left: 19px;
      margin-right: -26px; }
      .story-item__right .story-item__image .h {
        padding-left: 0; } }
  @media (min-width: 1200px) {
    .story-item__right .story-item__image .h {
      left: 40px;
      right: 40px;
      text-align: left;
      padding-left: 69px;
      padding-right: 26px; } }
  @media (max-width: 1199px) {
    .story-item__right .story-item__text {
      margin-left: 19px; } }
  @media (min-width: 1200px) {
    .story-item__right .story-item__text {
      max-width: 780px; } }
  @media (min-width: 1200px) {
    .story-item__right .story-item__text .title {
      margin-left: auto;
      padding-left: 40px;
      padding-right: 0; } }
  @media (min-width: 1200px) {
    .story-item__right .story-item__text .text {
      margin-left: auto;
      padding-left: 40px;
      padding-right: 0; } }

.tasting {
  padding: 61px 0 56px;
  background-color: #000; }
  @media (min-width: 1200px) {
    .tasting {
      padding: 68px 0 0; } }
  .tasting .heading {
    margin: 0 0 27px;
    font-weight: 300;
    color: #AC9D7A; }
    @media (min-width: 1200px) {
      .tasting .heading {
        margin: 0 0 86px; } }
    .tasting .heading .ja {
      text-indent: 2px;
      letter-spacing: 2px; }

.tasting-content {
  position: relative; }
  .tasting-content__grass {
    padding: 0 26px;
    text-align: center; }
    .tasting-content__grass img {
      display: block;
      margin: 0 auto; }
  .tasting-content__text {
    margin: 27px 0 44px;
    padding: 0 17px;
    text-align: center; }
    @media (min-width: 1200px) {
      .tasting-content__text {
        position: absolute;
        z-index: 1;
        left: 50%;
        margin: 0;
        text-align: left; }
        .tasting-content__text.text1 {
          top: 50px;
          transform: translateX(-580px); }
        .tasting-content__text.text2 {
          top: 50%;
          left: auto;
          right: 49%;
          transform: translateX(568px) translateY(-90%); }
          .tasting-content__text.text2 .title {
            justify-content: flex-start; }
          .tasting-content__text.text2 .hr {
            margin-left: 0;
            margin-right: -30px; }
          .tasting-content__text.text2 .text {
            padding-left: 110px; }
        .tasting-content__text.text3 {
          top: 55%;
          transform: translateX(-574px); } }
    .tasting-content__text .title {
      display: flex;
      flex-direction: column;
      margin: 0 0 6px; }
      @media (min-width: 1200px) {
        .tasting-content__text .title {
          flex-direction: row-reverse;
          align-items: center;
          justify-content: flex-end;
          margin-bottom: 15px; } }
      .tasting-content__text .title .ja {
        margin-bottom: 3px;
        font-size: 1.4rem;
        color: rgba(255, 255, 255, 0.64); }
        @media (min-width: 1200px) {
          .tasting-content__text .title .ja {
            margin-left: 28px; } }
      .tasting-content__text .title .en {
        font-family: Copperplate, "Copperplate Gothic Light", "Balthazar", fantasy;
        font-weight: 300;
        font-size: 2.8rem;
        color: #BAAB8F;
        letter-spacing: 6px;
        -webkit-text-stroke: 0; }
    .tasting-content__text .hr {
      margin: 6.5px auto 15px;
      width: 252px;
      height: 1px;
      background-color: transparent;
      background-image: url(../img/sec06/bar@2x.png);
      background-size: 100% 100%;
      border: 0; }
      @media (min-width: 1200px) {
        .tasting-content__text .hr {
          width: 466px;
          margin: 15px 0 20px;
          margin-left: -30px; } }
    .tasting-content__text .text {
      line-height: 2;
      font-size: 1.1rem;
      color: #C1BEB6; }
      @media (min-width: 1200px) {
        .tasting-content__text .text {
          font-size: 1.4rem; } }

.pairing {
  padding: 50px 0 40px;
  background-color: #DEDCDC; }
  .pairing .heading {
    margin-bottom: 27px;
    color: #AC9D7A; }
    @media (min-width: 922px) {
      .pairing .heading {
        margin-bottom: 65px; } }
    .pairing .heading .ja {
      letter-spacing: 4px; }
  .pairing__more {
    margin: 32px 0 0;
    padding: 0 26px;
    text-align: center; }
    @media (min-width: 922px) {
      .pairing__more {
        margin: 56px 0 0; } }
    .pairing__more a {
      transition: 235ms ease; }
      .pairing__more a:hover {
        opacity: 0.8; }

.pairing-swiper {
  padding-left: 17px;
  padding-right: 17px;
  overflow: hidden; }
  .pairing-swiper .swiper-slide {
    pointer-events: none;
    user-select: none;
    max-width: 717px;
    opacity: 0.65;
    transform: scale(0.8);
    transition: 1s ease; }
  .pairing-swiper .swiper-slide-active {
    opacity: 1;
    transform: scale(1); }
  .pairing-swiper-button-next, .pairing-swiper-button-prev {
    top: 30%;
    width: 25px;
    height: 44px;
    background-color: rgba(222, 220, 220, 0.79);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px 25px;
    border: 1px solid rgba(255, 255, 255, 0.79);
    transition: 235ms ease; }
    @media (min-width: 922px) {
      .pairing-swiper-button-next, .pairing-swiper-button-prev {
        top: 38%;
        width: 40px;
        height: 69px;
        background-size: 16px 39px; } }
    .pairing-swiper-button-next:hover, .pairing-swiper-button-prev:hover {
      opacity: 0.8; }
    .pairing-swiper-button-next:after, .pairing-swiper-button-prev:after {
      display: none; }
  .pairing-swiper-button-next {
    right: 5px;
    background-image: url(../img/sec07/angle-right.svg); }
    @media (min-width: 922px) {
      .pairing-swiper-button-next {
        right: 50%;
        transform: translateX(445px); } }
  .pairing-swiper-button-prev {
    left: 5px;
    background-image: url(../img/sec07/angle-left.svg); }
    @media (min-width: 922px) {
      .pairing-swiper-button-prev {
        left: 50%;
        transform: translateX(-445px); } }
  .pairing-swiper__content {
    max-width: 717px;
    width: 100%;
    margin: 0 auto; }
  .pairing-swiper__text {
    position: relative;
    z-index: 1;
    margin: -13px 9px 0;
    padding: 18px 20px 20px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.65);
    border: 1px solid #C2B48D; }
    @media (min-width: 922px) {
      .pairing-swiper__text {
        margin: -16px 13px 0; } }
    .pairing-swiper__text .title {
      margin: 0 0 8px;
      line-height: 1.6;
      font-size: 1.3rem;
      color: #5D574B;
      letter-spacing: 2px; }
      @media (min-width: 922px) {
        .pairing-swiper__text .title {
          font-size: 2.3rem; } }
    .pairing-swiper__text .hr {
      width: 237px;
      margin: 11px auto 8px;
      height: 1px;
      background: transparent url(../img/sec07/bar@2x.png) no-repeat center center;
      background-size: 100% 100%;
      border: 0; }
      @media (min-width: 922px) {
        .pairing-swiper__text .hr {
          width: 373px;
          height: 2px; } }
    .pairing-swiper__text .text {
      line-height: 1.9;
      font-feature-settings: "palt";
      font-size: 1.2rem;
      color: #002128;
      letter-spacing: 1.5px; }

.gift {
  padding: 36px 0 73px; }
  @media (min-width: 922px) {
    .gift {
      padding: 68px 0 98px; } }
  .gift .heading {
    color: #AC9D7A; }
    @media (max-width: 1199px) {
      .gift .heading {
        margin-bottom: 40px; } }
  .gift__container {
    max-width: 632px;
    margin: 0 auto;
    padding: 0 26px; }
    @media (min-width: 922px) {
      .gift__container {
        max-width: 1126px; } }

@media (min-width: 922px) {
  .gift-content {
    display: flex;
    align-items: center;
    margin: 0 -26px; } }

.gift-content__image {
  margin: 0 0 28px; }
  @media (min-width: 922px) {
    .gift-content__image {
      flex: 1 1 0%;
      margin-left: -70px;
      padding: 0 15px; } }

@media (min-width: 922px) {
  .gift-content__text {
    width: 50%;
    padding: 0 15px;
    padding-left: 60px; } }

.gift-content__text .title {
  margin: 0 0 20px;
  font-feature-settings: "palt";
  font-size: 2.1rem;
  color: #002128;
  letter-spacing: 5px; }
  @media (min-width: 922px) {
    .gift-content__text .title {
      margin-top: -10px;
      margin-bottom: 38px;
      line-height: 1.9;
      font-size: 3.5rem;
      text-indent: -.5em; } }

.gift-content__text .text {
  line-height: 1.8;
  font-size: 1.3rem;
  color: #5D574B; }
  @media (min-width: 922px) {
    .gift-content__text .text {
      font-size: 1.4rem; } }

.faq {
  padding: 38px 0 54px;
  color: #D9CFB0;
  background-color: #030000; }
  @media (min-width: 922px) {
    .faq {
      padding: 60px 0 117px; } }
  .faq .heading {
    margin-bottom: 50px; }
    @media (min-width: 922px) {
      .faq .heading {
        margin-bottom: 107px; } }
  .faq__container {
    max-width: 802px;
    margin: 0 auto;
    padding: 0 26px; }

.faq-item {
  display: block;
  margin: 0; }
  .faq-item.active .faq-down {
    transform: translateY(-50%) rotateX(180deg); }
  .faq-item dt, .faq-item dd {
    margin: 0;
    padding: 0; }
  .faq-item dt {
    cursor: pointer;
    position: relative;
    padding: 10px 0;
    padding-left: 1.4em;
    padding-right: 40px;
    user-select: none;
    line-height: 2;
    font-size: 1.3rem;
    transition: 235ms ease; }
    .faq-item dt:hover {
      opacity: 0.8; }
    .faq-item dt:before {
      content: "Q.";
      position: absolute;
      left: 0;
      letter-spacing: 2px; }
  .faq-item dd {
    line-height: 1.8;
    font-size: 1.1rem;
    color: #fff; }
    @media (min-width: 922px) {
      .faq-item dd {
        font-size: 1.2rem; } }
  .faq-item .faq-down {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 15px;
    height: 6px;
    transform: translateY(-50%);
    transition: 235ms ease; }
    .faq-item .faq-down svg {
      display: block;
      width: 15px;
      height: 6px; }
  .faq-item .text {
    padding: 10px 0;
    border-top: 1px solid #707070; }
    @media (min-width: 922px) {
      .faq-item .text {
        padding-right: 80px; } }
    .faq-item .text a:hover {
      text-decoration: none; }

.apply {
  padding: 50px 0 44px;
  background-color: #DEDCDC; }
  @media (min-width: 922px) {
    .apply {
      padding: 59px 0 93px; } }
  @media (max-width: 1199px) {
    .apply .heading {
      margin-bottom: 30px; } }
  .apply__container {
    max-width: 1239px;
    margin: 0 auto;
    padding: 0 26px; }

.apply-step {
  position: relative;
  z-index: 1;
  max-width: 500px;
  margin: 0 auto 29px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #C2B48D;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  @media (min-width: 922px) {
    .apply-step {
      max-width: none; } }
  .apply-step__inner {
    padding: 44px 22px; }
    @media (min-width: 922px) {
      .apply-step__inner {
        padding: 63px 22px 85px; } }

.apply-form form.klaviyo-form {
  max-width: 486px !important;
  float: none !important;
  margin: 0 auto !important;
  padding: 0 !important;
  background-color: transparent !important; }
  .apply-form form.klaviyo-form .needsclick {
    min-height: 1px !important; }
  .apply-form form.klaviyo-form input[type="text"]:not(:focus),
  .apply-form form.klaviyo-form input[type="email"] {
    font-family: "A1明朝", "游明朝", "ヒラギノ明朝 ProN W3", "ＭＳ Ｐ明朝", serif !important;
    border-color: #969494 !important; }
    .apply-form form.klaviyo-form input[type="text"]:not(:focus)::placeholder,
    .apply-form form.klaviyo-form input[type="email"]::placeholder {
      font-family: "A1明朝", "游明朝", "ヒラギノ明朝 ProN W3", "ＭＳ Ｐ明朝", serif !important; }
  .apply-form form.klaviyo-form input[type="email"] {
    max-width: 367px;
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
  .apply-form form.klaviyo-form button[type="button"] {
    width: 375px !important;
    margin: 0 auto 0 !important;
    padding: 18px 10px !important;
    font-family: "A1明朝", "游明朝", "ヒラギノ明朝 ProN W3", "ＭＳ Ｐ明朝", serif !important;
    font-weight: 400 !important;
    font-size: 2rem !important;
    color: #D9CFB0 !important;
    letter-spacing: 3px !important;
    border-radius: 0 !important;
    transition: 235ms ease !important; }
    @media (min-width: 922px) {
      .apply-form form.klaviyo-form button[type="button"] {
        font-size: 2.3rem; } }
    .apply-form form.klaviyo-form button[type="button"]:hover {
      opacity: 0.8 !important; }

.apply-form .ql-container {
  font-family: "A1明朝", "游明朝", "ヒラギノ明朝 ProN W3", "ＭＳ Ｐ明朝", serif !important; }

.apply-form .ql-editor p,
.apply-form .ql-editor [class*="ql-font"] {
  font-family: "A1明朝", "游明朝", "ヒラギノ明朝 ProN W3", "ＭＳ Ｐ明朝", serif !important;
  text-align: center !important; }

.policies {
  padding: 95px 0 76px; }
  .policies__container {
    max-width: 1074px;
    margin: 0 auto;
    padding: 0 26px; }
  .policies__title {
    margin: 0 0 75px;
    font-size: 2rem;
    color: #002128;
    letter-spacing: 1px;
    text-align: center; }
  .policies__content {
    line-height: 1.6;
    font-size: 1.3rem;
    font-feature-settings: "palt";
    letter-spacing: 1.5px; }
    .policies__content > *:first-child {
      margin-top: 0; }
    .policies__content > *:last-child {
      margin-bottom: 0; }
    .policies__content h3 {
      margin: 65px 0 15px;
      font-size: 1.5rem;
      color: #002128;
      -webkit-text-stroke: 0.2px; }
    .policies__content p {
      margin: 15px 0 2em; }
      .policies__content p + ul {
        margin-top: -1em; }
    .policies__content a {
      color: currentColor;
      text-decoration: none; }
      .policies__content a:hover {
        text-decoration: underline; }
    .policies__content ol {
      padding-left: 1.5em; }
      .policies__content ol > li {
        margin-bottom: 0.5em; }
    .policies__content ul {
      margin: 5px 0 0;
      list-style: none;
      padding-left: 1em; }
      .policies__content ul li {
        position: relative; }
        .policies__content ul li:before {
          content: "・";
          position: absolute;
          left: -0.8em;
          font-size: 100%; }

.thanks-section {
  padding: 80px 0; }
  @media (min-width: 922px) {
    .thanks-section {
      padding: 120px 0; } }
  .thanks-section__container {
    max-width: 1074px;
    margin: 0 auto;
    padding: 0 26px; }
  .thanks-section__text {
    line-height: 2;
    font-size: 1.4rem;
    text-align: center; }
    @media (min-width: 922px) {
      .thanks-section__text {
        font-size: 1.5rem; } }
    .thanks-section__text a {
      color: currentColor; }
      .thanks-section__text a:hover {
        text-decoration: none; }

.line-popup {
  max-width: 480px;
  width: 100%; }
  .line-popup__container {
    max-width: 480px;
    width: 100%;
    padding: 26px;
    font-size: 1.4rem;
    text-align: center;
    background-color: #fff;
    border-radius: 10px; }
    .line-popup__container .small {
      font-size: 1.2rem; }
    .line-popup__container .line-qr {
      max-width: 200px;
      width: 60%;
      margin: 0 auto 20px; }

.lity-iframe-container iframe {
  background-color: #fff; }

.lity {
  background-color: rgba(0, 0, 0, 0.02); }

.lity-iframe .lity-container {
  max-width: 540px; }
  .lity-iframe .lity-container .lity-iframe-container {
    padding-top: 110%; }

.lity-content:after {
  border-radius: 10px; }

.lity-close {
  color: #fff; }
