.policies {
  padding: 95px 0 76px;
  &__container {
    max-width: 1074px;
    margin: 0 auto;
    padding: 0 26px;
  }

  &__title {
    margin: 0 0 75px;
    font-size: 2rem;
    color: #002128;
    letter-spacing: 1px;
    text-align: center;
  }

  &__content {
    line-height: 1.6;
    font-size: 1.3rem;
    font-feature-settings: "palt";
    letter-spacing: 1.5px;
    > * {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h3 {
      margin: 65px 0 15px;
      font-size: 1.5rem;
      color: #002128;
      -webkit-text-stroke: 0.2px;
    }

    p {
      margin: 15px 0 2em;

       + ul {
         margin-top: -1em;
       }
    }

    a {
      color: currentColor;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    ol {
      padding-left: 1.5em;
      > li {
        margin-bottom: 0.5em;
      }
    }
    ul {
      margin: 5px 0 0;
      list-style: none;
      padding-left: 1em;

      li {
        position: relative;
        &:before {
          content: "・";
          position: absolute;
          left: -0.8em;
          font-size: 100%;
        }
      }
    }
  }
}