/**
 * Layout
 * レイアウト全般に関するものはここに書く
 */
body {
	display: flex;
  flex-direction: column;
}
.guide {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  pointer-events: none;

  > * {
    position: absolute;
    top: 0;
    height: 100%;
    color: #0ff;
  }

  .guide-center {
    left: 50%;
    width: 1px;
    background-color: currentColor;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      display: block;
      width: 1px;
      height: 100%;
      background-color: currentColor;
    }
    &:before {
      left: -40px;
    }
    &:after {
      left: 40px;
    }
  }
  .guide-container {
    left: 0;
    right: 0;
    max-width: 1074px;
    width: calc(100% - 52px);
    margin: 0 auto;
    border-left: 1px solid currentColor;
    border-right: 1px solid currentColor;
  }
}
.layout-wrapper {
  display: flex;
	flex-direction: column;
  max-width: 1920px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  // padding-top: 60px;
  // overflow: hidden;
	background-color: $bg-color-layout-wrapper;
  box-shadow: 0 0 3px 0 rgba(#000, .2);

	// @media (min-width: $sm-min) {
	// 	padding-top: 88px;
	// }

  // @media (min-width: $lg-min) {
  //   // padding-top: 98px;
	// }

	// @media (min-width: $nav-min) {
	// 	padding-top: 116px;
	// }
}
body.scrolldown:not(.drawer-active) {
  .layout-header {
    transform: translateY(-100%);
    transition: 235ms ease 350ms;
  }
}
.layout-header {
  position: sticky;
  top: 0;
  z-index: 9999;
  transition: 200ms ease;
}
.layout-main {
  flex: 1 0 auto;
}

// Container
.container {
	max-width: 420px + $gatter;
	margin-left: auto;
	margin-right: auto;
	@include safe-left($gatter-size);
	@include safe-right($gatter-size);

	@media (min-width: $sm-min) {
		max-width: 520px + $gatter;
	}

	@media (min-width: $md-min) {
		max-width: 680px + $gatter;
	}

	@media (min-width: $lg-min) {
		max-width: 880px + $gatter;
	}
	@media (min-width: $xl-min) {
		max-width: 1124px + $gatter;
	}
}

.row {
	margin-left: -$gatter-size;
	margin-right: -$gatter-size;
}