.thanks-section {
  padding: 80px 0;

  @media (min-width: $lg-min) {
    padding: 120px 0;
  }

  &__container {
    max-width: 1074px;
    margin: 0 auto;
    padding: 0 26px;
  }

  &__text {
    line-height: 2;
    font-size: 1.4rem;
    text-align: center;

    @media (min-width: $lg-min) {
      font-size: 1.5rem;
    }

    a {
      color: currentColor;
      &:hover {
        text-decoration: none;
      }
    }
  }
}


.line-popup {
  max-width: 480px;
  width: 100%;
  &__container {
    max-width: 480px;
    width: 100%;
    padding: 26px;
    font-size: 1.4rem;
    text-align: center;
    background-color: #fff;
    // box-shadow: 0 0 15px 1px rgba(#000, .2);
    border-radius: 10px;


    .small {
      font-size: 1.2rem;
    }

    .line-qr {
      max-width: 200px;
      width: 60%;
      margin: 0 auto 20px;
    }
  }
}
.lity-iframe-container {
  iframe {
    background-color: #fff;
  }
}
.lity {
  background-color: rgba(#000, .02);
}
.lity-iframe {
  .lity-container {
    max-width: 540px;
    // min-height: 580px;
    .lity-iframe-container {
      padding-top: 110%;
    }
  }
}
.lity-content {
  &:after {
    border-radius: 10px;
  }
}
.lity-close {
  color: #fff;
}